import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";

// Local imports
import Layout from "src/layouts/LayoutWhiteHeader";
import PostListing from "src/components/generic/PostListingDetailed/PostListingDetailed";
import config from "data/SiteConfig";

const TagTemplate = ({ data, pageContext }) => (
	<Layout>
		<main>
			<Helmet
				title={`Posts tagged as "${pageContext.tag}" | ${config.siteTitle}`}
			/>
			<PostListing postEdges={data.allMdx.edges} />
		</main>
	</Layout>
);
export default TagTemplate;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
	query TagPage($tag: String) {
		allMdx(
			limit: 1000
			sort: { fields: [fields___date], order: DESC }
			filter: { frontmatter: { tags: { in: [$tag] } } }
		) {
			totalCount
			edges {
				node {
					fields {
						slug
						date(formatString: "MMMM DD, YYYY")
					}
					frontmatter {
						title
						tags
						date
						categories
					}
				}
			}
		}
	}
`;
